export const MsiProductDetailsContainer = () => import('./msi-product-details-container');
export const MsiProductImage = () => import('./msi-product-image');
export const MsiProductLongDescription = () => import('./msi-product-long-description');
export const MsiProductCompatibleDevices = () => import('./msi-product-compatible-devices');
export const MsiProductSummaryDetails = () => import('./msi-product-summary-details');
export const MsiProductConfigureSummaryDetails = () => import('./msi-product-configure-summary-details');
export const MsiProductDetailsProductSpecification = () => import('./msi-product-details-product-specification');
export const MsiProductResources = () => import('./msi-product-resources');
export const MsiProductAddToCartButton = () => import('./msi-product-add-to-cart-button');
export const MsiProductDetailsQuantity = () => import('./msi-product-details-quantity');
export const MsiProductName = () => import('./msi-product-name');
export const MsiProductConfigureContainer = () => import('./msi-product-configure-container');
export const MsiProductVariants = () => import('./msi-product-variants');
export const MsiProductConfigure = () => import('./msi-product-configure');
export const MsiProductConfigureSummary = () => import('./msi-product-configure-summary');
export const MsiProductConversionWarning = () => import('./msi-product-conversion-warning');
export const MsiRelatedProducts = () => import('./msi-related-products');
